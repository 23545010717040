import { QueryCache, QueryClient } from "@tanstack/react-query";
import { HttpError } from "../../utils/jquery_helper";
import { error as errorToast } from "../../utils/toasts";

export const SialogicQueryClient = new QueryClient({
  defaultOptions: {},
  queryCache: new QueryCache({
    // global error handling ... display an error toast
    onError(error) {
      if (error instanceof HttpError) {
        if ((error as HttpError).request?.status == 401) {
          void errorToast(I18n.t("frontend.unauthorized"), error.message);
          return;
          // Handle 401 error here
        }
        // Handle 401 error here
      }
      void errorToast(I18n.t("frontend.error"), error.message);
    },
  }),
});
