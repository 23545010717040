import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/system";
import * as React from "react";
import { DefaultTheme } from "../styling/theme";

export interface AppRootProps extends React.PropsWithChildren {
  queryDevTools?: boolean;
}

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppContextProvider } from "./app_context/app_context_provider";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SialogicQueryClient } from "./sialogic_query_client";

export const AppRoot: React.FunctionComponent<AppRootProps> = ({
  queryDevTools = false,
  ...props
}) => {
  return (
    <QueryClientProvider client={SialogicQueryClient}>
      <AppContextProvider>
        <StyledEngineProvider injectFirst>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={DefaultTheme}>{props.children}</ThemeProvider>
            {queryDevTools ? (
              <ReactQueryDevtools
                buttonPosition="bottom-left"
                client={SialogicQueryClient}
                initialIsOpen={false}
              />
            ) : null}
          </DndProvider>
        </StyledEngineProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};
