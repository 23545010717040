/// <reference types="../definitions/index"/>
import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";
// this way of import should  make ts-node tests work. Probably should not be changed
import { defaultTo } from "lodash";
import * as swal from "sweetalert2";
export const toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 3000,
});

type ToastFunction = (
  title: string,
  text?: string,
) => Promise<SweetAlertResult>;

export interface Toasts {
  success: ToastFunction;
  error: ToastFunction;
  warn: ToastFunction;
  info: ToastFunction;
  toast: typeof swal;
}

export function success(
  title: string,
  text?: string,
): Promise<SweetAlertResult> {
  return toast.fire({
    title: title,
    text: text,
    icon: "success",
  });
}

export function error(
  title: string,
  text?: string,
  showConfirmButton?: boolean,
): Promise<SweetAlertResult> {
  return toast.fire({
    title: defaultTo(title, I18n.t("frontend.error")),
    text: text,
    icon: "error",
    showConfirmButton: showConfirmButton,
    showClass: {
      popup: "animated fadeInDown faster",
    },
    hideClass: {
      popup: "animated fadeOutUp faster",
    },
  });
}

export function warn(title: string, text?: string): Promise<SweetAlertResult> {
  return toast.fire({
    title: title,
    text: text,
    icon: "warning",
    showClass: {
      popup: "animated fadeInDown faster",
    },
    hideClass: {
      popup: "animated fadeOutUp faster",
    },
  });
}

export function info(title: string, text?: string): Promise<SweetAlertResult> {
  return toast.fire({
    title: title,
    text: text,
    icon: "info",
    showClass: {
      popup: "animated fadeInDown faster",
    },
    hideClass: {
      popup: "animated fadeOutUp faster",
    },
  });
}

export function initializeFlashMessages() {
  return Promise.all(
    $('[data-toggle="flash-message"]').map((index, element) => {
      const flashMessage = $(element);
      const title = flashMessage.attr("data-title");
      const text = flashMessage.attr("data-text");
      const type = flashMessage.attr("data-type");
      return toast
        .fire({
          title,
          text,
          icon: type as SweetAlertIcon,
          showCloseButton: true,
          timer: null,
          showClass: {
            popup: "animated fadeInDown faster",
          },
          hideClass: {
            popup: "animated fadeOutUp faster",
          },
        })
        .then(() => {
          flashMessage.remove();
        });
    }),
  );
}

export function destroyFlashMessages() {}
